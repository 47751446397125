import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"

import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import CardPost from "../components/v2023/UI/Card/card-post"
import { Modal } from "antd"
import Menu from "../components/v2023/Blog/Menu/menu"
import Identity from "../components/v2023/Identity/identity"
import Certifications from "../components/v2023/Certifications/certifications"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const NewBlog = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const categories = data.categories.edges
    const principal = data.principal
    const highlights = data.highlights.edges
    const posts = data.posts.edges
    const recommended = data.recommended.edges
    const blog = data.blog.frontmatter
    const baseUrl = lang === "en" ? "" : `/${lang}`


    const [showSearch, setShowSearch] = useState(false)
    const [postSize, setPostSize] = useState(9)
    const [postListed, setPostListed] = useState(posts.slice(0, postSize))

    const showMorePosts = async () => {
        let s = postSize

        if (s < posts.length) {
            s += 9
            setPostSize(s)
            setPostListed(posts.slice(0, s))
        }
    }

    const elements = {
        li: ({ node, ...props }) => {
            return <li {...props}>
                <div>{props.children}</div>
            </li>
        }
    }

    const pathSearch = {
        en: "/blog/search",
        es: "/es/blog/buscar"
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <Menu categories={categories} blog={blog} lang={lang} />

                <div className="main__section main__section--100 white-section brown">
                    <div className="container container--pb0">
                        <h1 className="merriweather">{page.title}</h1>
                    </div>
                </div>

                <div className="main__section main__section--50 main__section--50--lg">
                    <div className="container flex-start">
                        <div className="container__50" data-aos="fade-right">
                            <CardPost
                                lang={lang}
                                name={principal.name}
                                image={principal.photo_2}
                                url={`${baseUrl}/blog/${principal.url}`}
                                date={principal.creation_datetime}
                                tag={principal.blog_categories[0].name}
                                big={true}
                                tagUrl={`${baseUrl}/blog/${principal.blog_categories[0].url}`}
                            />
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            {highlights.map((post, index) => {
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.name}
                                    image={post.node.photo_2}
                                    url={`${baseUrl}/blog/${post.node.url}`}
                                    date={post.node.creation_datetime}
                                    tag={post.node.blog_categories[0].name}
                                    tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                    horizontal={true}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section">
                    <div className="container">
                        <div className="tile tile--gray">
                            <h2>{page.search_title}</h2>
                            <div className="tile__body">
                                <form className="input-group" action={pathSearch[lang]}>
                                    <input type="text" name="text" className="input" placeholder={page.search_placeholder} />
                                    <button className="button">{page.search_button}</button>
                                </form>
                            </div>
                            <a className="link--big" onClick={() => setShowSearch(true)}>{page.search_show_all} <i className="icon-arrow-right"></i></a>
                        </div>
                    </div>

                    <Modal
                        centered
                        onCancel={() => setShowSearch(false)}
                        visible={showSearch}
                        width={"100%"}
                        footer={null}
                    >
                        <h2>{page.search_title}</h2>

                        <form className="input-group" action={pathSearch[lang]}>
                            <input type="text" name="text" className="input" placeholder={page.search_placeholder} />
                            <button className="button">{page.search_button}</button>
                        </form>

                        <div className="tag-group">
                            {categories.map((category, index) => {
                                return <Link to={`${baseUrl}/blog/${category.node.url}`} className="tag tag--lg" key={index}>{category.node.name}</Link>
                            })}

                        </div>
                    </Modal>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="grid-lg-3">
                            {postListed.map((post, index) => {
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.name}
                                    image={post.node.photo_2}
                                    url={`${baseUrl}/blog/${post.node.url}`}
                                    tag={post.node.blog_categories[0].name}
                                    tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                    imgClassName={"card__header__img-wide"} />
                            })}
                        </div>
                        {postListed.length < posts.length && <div className="container__see-more">
                            <button className="button button--xl button--secondary" onClick={showMorePosts}>{blog.view_more}</button>
                        </div>}
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="container__text">
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={elements} />
                            <div className="tile tile--white--nb">
                                <ReactMarkdown children={page.description_card} rehypePlugins={[rehypeRaw]} components={elements} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{blog.recommended}</h2>

                        <div className="grid-lg-3-h">
                            {recommended.map((post, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.name}
                                    image={post.node.photo_2}
                                    url={`${baseUrl}/blog/${post.node.url}`}
                                    date={post.node.creation_datetime}
                                    tag={post.node.blog_categories[0].name}
                                    tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                    showMore={true}
                                    animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <Certifications />

                <Identity lang={lang} />
            </main>
        </NewLayout>

    )
}

export default NewBlog

export const blogQuery = graphql`
    query ($langKey: String) {
        page: allStrapiBlog (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    search_title
                    search_placeholder
                    search_button
                    search_show_all
                    description
                    description_card
                }
            }
        }
        categories: allStrapiBlogCategories (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    id
                    name
                    url
                }
            }
        }
        blog: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "blog"
                }
            }) {
            frontmatter {
                url
                subscribe
                see_more
                see_less
                view_more
                recommended
                explore
                newsletter {
                    title
                    description
                }
            }
        }
        principal: strapiBlogPosts (locale: {eq: $langKey}, principal: {eq: true}) {
            id
            name
            url
            blog_categories {
                id
                name
                url
            }
            photo_2 {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 972
                            quality: 100
                            placeholder: BLURRED
                            formats: [WEBP]
                            breakpoints: [200, 420, 756]
                        )
                    }
                }
            }
            creation_datetime(formatString: "DD/MM/YYYY", locale: $langKey)
        }
        highlights: allStrapiBlogPosts (
            filter: { highlight: {eq: true}, locale: {eq: $langKey}, id: { ne: "Blog-posts_61" } }
            sort: { fields: [creation_datetime], order: [DESC] }
            limit: 3
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                    }
                }
            }
        }
        posts: allStrapiBlogPosts (
            filter: { locale: {eq: $langKey}, id: { ne: "Blog-posts_61" } }
            sort: { fields: [creation_datetime], order: [DESC] }
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 700
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    content
                }
            }
        }
        recommended: allStrapiBlogPosts (
            filter: { recommended: {eq: true}, locale: {eq: $langKey}, id: { ne: "Blog-posts_61" } }
            sort: { fields: [creation_datetime], order: [DESC] }
            limit: 3
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 912
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    creation_datetime(formatString: "DD/MM/YYYY", locale: $langKey)
                }
            }
        }
    }
`
